import { basicTask } from "./task";
import { swiperInit } from "./swiper";
gsap.registerPlugin(MotionPathPlugin);
const bodyWrap = document.querySelector("body");
const sideNav = document.querySelector(".sidenav-group");
const hamburger = document.querySelector(".hamburger");
const menuMobile = document.querySelector(".mobile-nav-wrap");
const searchWrap = document.querySelector(".search-overlay");

document.addEventListener("DOMContentLoaded", () => {
	try {
		if ($(window).width() > 1200.1) {
		}
	} catch (error) { }
	mappingFunc();
	swiperInit();
	basicTask();
	generalFunction.init();
});

window.addEventListener("resize", function () {
	let width = this.window.innerWidth;
	if (width <= 1200.1) {
		mappingFunc();
		generalFunction.init();
	} else {
	}
});
window.addEventListener("scroll", function () {
	const width = this.window.innerWidth;
	const scrollTop = window.scrollY || document.documentElement.scrollTop;
	if (width >= 1200.1) {
		if (
			document.body.scrollTop > 100 ||
			document.documentElement.scrollTop > 100
		) {
			bodyWrap.classList.add("minimize");
		} else {
			bodyWrap.classList.remove("minimize");
		}
	}
	if (scrollTop > window.innerHeight) {
		sideNav.classList.add("show");
	} else {
		sideNav.classList.remove("show");
	}
});
document.addEventListener("click", (event) => {
	if (!event.target.closest(".search-toggle, .search-overlay")) {
		searchWrap.classList.remove("is-open");
	}
	if (!event.target.closest(".mobile-nav-wrap, .site-menu-toggle")) {
		menuMobile.classList.remove("is-open");
		bodyWrap.classList.remove("overlay-bg");
		hamburger.classList.remove("is-active");
	}
});

Fancybox.bind("[data-fancybox]", {
	parentEl: document.body[0], // Element containing main structure
});

const generalFunction = {
	circlePath: () => {
		try {
			if ($('body').hasClass('about-page')) {

				const circlePath = MotionPathPlugin.convertToPath(
					"#svg-circle",
					false
				)[0];
				circlePath.id = "circlePath";
				document.querySelector(".half-circle  svg").prepend(circlePath);

				let items = gsap.utils.toArray(".half-circle .circle-item"),
					numItems = items.length,
					itemStep = 1 / numItems,
					wrapProgress = gsap.utils.wrap(0, 1),
					snap = gsap.utils.snap(itemStep),
					wrapTracker = gsap.utils.wrap(0, numItems),
					tracker = { item: 0 };

				// Function to update active state
				const updateActiveState = () => {
					// Remove active class from all items
					items.forEach((item) => item.classList.remove("active"));

					// Calculate which item is at the center (at progress 0.5)
					const centerIndex = Math.round(tracker.item) % numItems;
					items[centerIndex]?.classList.add("center");
				};

				// Set up motion paths
				gsap.set(items, {
					motionPath: {
						path: circlePath,
						align: circlePath,
						alignOrigin: [0.5, 0.5],
						end: (i) => gsap.utils.wrap(0, 1, i / numItems - 0.5),
					},
					scale: 1,
					onUpdate: function () {
						// Update active state whenever the animation updates
						updateActiveState();
					},
				});

				// Initial active state
				updateActiveState();
			}

		} catch (error) {
			console.error("Carousel initialization error:", error);
		}
	},
	a11yUser: () => {
		const wrapForms = document.querySelectorAll(".wrap-form");
		wrapForms.forEach(function (form) {
			const submitInput = form.querySelector("input[type='submit']");
			const labelFor = submitInput.getAttribute("name");
			const labelSubmit = form.querySelector(".frm-btnwrap .label");
			if (labelFor !== null) {
				if (labelSubmit !== null) {
					labelSubmit.textContent = "Submit Button";
					labelSubmit.setAttribute("for", labelFor);
				}
			}
		});
	},
	appendHTML: () => {
		if ($("body").hasClass("news-detail-page")) {
			$(".news-comment-form").appendTo(".new-comment-wrap");
		}
	},
	hideElement: () => {
		if ($(window).width() < 1200.1) {
			[".searchbox", ".nav-primary-menu"]
				.map((selector) => document.querySelector(selector))
				.forEach((el) => (el.style.display = "flex"));
		}
	},
	init: () => {
		generalFunction.appendHTML();
		generalFunction.hideElement();
		generalFunction.circlePath();
	},
};



function mappingFunc() {
	new MappingListener({
		selector: ".language-wrap",
		mobileWrapper: ".mobile-top-nav",
		mobileMethod: "appendTo",
		desktopWrapper: ".search-wrap",
		desktopMethod: "insertBefore",
		breakpoint: 1200.1,
	}).watch();
	new MappingListener({
		selector: ".nav-primary-menu",
		mobileWrapper: ".mobile-nav-wrap",
		mobileMethod: "appendTo",
		desktopWrapper: ".nav-brand",
		desktopMethod: "insertAfter",
		breakpoint: 1200.1,
	}).watch();
	new MappingListener({
		selector: ".searchbox",
		mobileWrapper: "main",
		mobileMethod: "appendTo",
		desktopWrapper: ".search-toggle",
		desktopMethod: "insertAfter",
		breakpoint: 1200.1,
	}).watch();
}
